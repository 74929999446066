.page {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
}

.heading {
  margin-bottom: 30px;
}

.checkbox {
  margin-bottom: 20px;
  @media (min-width: 768px) {
    width: 70%;
  }
}

.button {
  margin-top: 30px;
  cursor: pointer;
  text-align: center;
  background-color: #1c1d44;
  color: #fff;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  font-size: 16px;
}

.button:hover,
.button:focus {
  background-color: #5b5b8f;
}

.spinner {
  width: 100%;
  margin-top: 10px;
  // border: 1px solid red;
  min-height: 30px;
  display: flex;
  justify-content: center;
}
