@import "../../../constants.scss";

.wrapper {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  font-size: 1rem;

  a {
    color: #6c757d;
    padding: 10px;
    transition: all 0.2s ease-in;

    &:hover,
    &:focus {
      color: #343a40;

      border-radius: 5px;
      transform: translateY(-3px);
      transition: all 0.2s ease-in;
      background-color: rgba(#6c757d, 10%);
    }
  }
  .active {
    color: $Red;
    cursor: auto;
    // &:hover {
    //   color: $Red;
    // }
  }
}

@media (min-width: 768px) {
  .wrapper {
    font-size: .875rem;
    column-gap: 10px;
  }
}

@media (min-width: 872px) {
  .wrapper {
    font-size: 1rem;
    column-gap: 30px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: 100%;
    height: fit-content;
    z-index: 3;
    gap: 0px;
    flex-direction: column;
  }
}
