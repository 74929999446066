.page {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
}

.heading {
  margin-bottom: 30px;
}

.block {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    margin-top: 0px;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 250px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 534px;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
}

.label {
  font-size: 14px;
  margin-bottom: 5px;
}

.input {
  font-size: 14px;
  padding: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  outline: none;
}

.input:hover,
.input:focus {
  border-color: #1890ff;
}

.button {
  margin-top: 30px;
  cursor: pointer;
  text-align: center;
  background-color: #1c1d44;
  color: #fff;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  font-size: 16px;
}

.button:hover,
.button:focus {
  background-color: #5b5b8f;
}

.message {
  margin: 20px 0px;
}

.required {
  color: #f51c1c;
}

.spinner {
  width: 100%;
  margin-top: 10px;
  // border: 1px solid red;
  min-height: 30px;
  display: flex;
  justify-content: center;
}

.link:hover,
.link:focus {
  opacity: .7;
}
