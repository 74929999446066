.chartblock {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
}

.statslink {
  margin-left: 10px;
  margin-top: 25px;
  font-size: 16px;
}

@media (min-width: 480px) {
  .chartblock {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .chartblock {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .chartblock {
    width: 40%;
  }
}