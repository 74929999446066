@import "/src/constants.scss";

.section {
  padding-left: 15px;
  padding-right: 15px;
}

.textsum {
  margin-top: 0;
  margin-bottom: 0;
}

.athletename,
.athleteid {
  margin: 0;
}

.athletename {
  margin-bottom: 20px;
}

.trowheading {
  background-color: $DarkBlue;
  color: $White;
}

.heading {
  font-size: 16px;
  font-weight: normal;
}

.theading,
.tcell {
  border: 1px solid grey;
  padding: 5px;
  text-align: center;
}

// ******** удалить после иконок клубов **********
.fifty {
  color: red;
}

.twentyfive {
  color: #9500ff;
}
// **********************************************

.textblock {
  width: 90%;
}

.starts,
.volunteerings {
  width: 270px;
}

.starts {
  margin-bottom: 30px;
}

.spinner {
  width: 100%;
  min-height: 60px;
  width: 290px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 480px) {
  .starts,
  .volunteerings {
    width: 350px;
  }
}

@media (min-width: 768px) {
  .textblock {
    width: 70%;
  }

  .starts,
  .volunteerings {
    width: 400px;
  }
}

@media (min-width: 992px) {
  .starts,
  .volunteerings {
    width: 500px;
  }
}
