.link:hover {
  opacity: .7;
}

.tcell {
  border: 1px solid grey;
  padding: 5px;
  text-align: center;
  width: 18%;
}

.nonclickableicon {
  opacity: .3;
}
