@import "../../constants.scss";

.wrapper {
  width: 100%;
  background-color: #f8f9fa;
  height: 80px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 1px 3px darken(#f8f9fa, 5%);
  .menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    gap: 20px;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .wrapper {
    position: relative;
    width: 100%;
    height: 80px;

    .menu {
      z-index: 2;
      position: fixed;
      top: 0;
      padding-top: 60px;
      padding-left: 20px;
      padding-right: 20px;
      right: -110%;
      height: 480px;
      flex-direction: column;
      gap: 10px;
      justify-content: flex-start;
      background-color: white;
      box-shadow: 0px 5px 10px 0px #ccc;
      transition: all 0.3s ease 0s;
      &.active {
        position: fixed;
        right: 0;
        transition: all 0.3s ease 0s;
      }
      a {
        width: 100%;
        border-bottom: 1px dashed #ccc;
      }
    }
    .header_burger {
      display: block;
      position: absolute;
      top: 30px;
      right: 30px;
      width: 30px;
      height: 20px;
      cursor: pointer;
      z-index: 3;
    }
    &.active {
      .header_burger {
        position: fixed;
        top: 30px;
        right: 30px;
      }
    }
    .header_burger span {
      background-color: $Red;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      top: 9px;
      transition: all 0.3s ease 0s;
    }
    .header_burger:before,
    .header_burger:after {
      content: "";
      background-color: $Red;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      transition: all 0.3s ease 0s;
    }

    .header_burger:before {
      top: 0;
    }
    .header_burger:after {
      bottom: 0;
    }
    .header_burger.active span {
      transform: scale(0);
    }
    .header_burger.active:before {
      transform: rotate(45deg);
      top: 9px;
    }
    .header_burger.active:after {
      transform: rotate(-45deg);
      bottom: 9px;
    }
  }
}

@media (min-width: 769px) {
  .menu {
    gap: 30px;
  }
}
