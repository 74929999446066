.section {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
}

.statistics {
  padding-left: 15px;
  padding-bottom: 0;
}

.summary {
  margin-bottom: 15px;
}

.textsum {
  margin-top: 0;
  margin-bottom: 0;
}

.athletename,
.athleteid {
  margin: 0;
}

.athleteid {
  margin-bottom: 10px;
  font-size: 18px;
}

.tableblock {
  padding-right: 15px;
  margin-bottom: 20px;
}

.results {
  width: 100%;
  margin-right: 0;
  margin-bottom: 20px;
}

.volunteerings {
  width: 100%;
  margin-bottom: 20px;
}

.heading {
  font-size: 16px;
  font-weight: normal;
}

.theading,
.tcell {
  border: 1px solid grey;
  padding: 5px;
  text-align: center;
}

.tlink {
  color: rgba(0,0,0,0.85);
}

.tlink:hover,
.tlink:focus {
  text-decoration: underline;
  color: #de9636;
}

.date {
  width: 20%;
}

.location {
  width: 30%;
}

.time,
.role {
  width: 25%;
}

.pace {
  width: 25%;
}

.externallink:hover,
.externallink:focus {
  color: rgba(66, 170, 255, 0.656);
}

.wtext {
  font-size: 16px;
  line-height: 21px;
  padding-top: 0;
  padding-bottom: 15px;
  padding-left: 15px;
}

.wtextres,
.wtextvol {
  font-size: 16px;
}

.tablecaption {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

.tablecaption {
  margin-bottom: 20px;
}

.spinner {
  width: 100%;
  min-height: 60px;
  width: 290px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartblock {
  display: none;
}

.chartblockvolunt {
  width: 70%;
}

.chartlink {
  font-size: 16px;
}

@media (min-width: 768px) {
  .tableblock {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .resultwrapper,
  .volunteeringwrapper {
    display: flex;
    justify-content: space-between;
  }

  .volunteeringwrapper {
    margin-bottom: 0;
  }

  .resultwrapper {
    margin-bottom: 40px;
  }

  .tableblock,
  .chartblock {
    padding-right: 0;
  }

  .tableblock {
    width: 40%;
    margin-bottom: 0;
  }

  .chartblock {
    width: 55%;
  }

  .chartblock {
    display: block;
    padding-left: 10px;
  }

  .chartcaption {
    text-align: left;
    margin-bottom: 10px;
    
    color: #000;
    font-weight: 600;
    font-size: 16px;
  }

  .chartcaptionvolunt {
    margin-bottom: 15px;
  }

  .volunteeringtwrapper {
    margin-bottom: 20px;
  }

  .chartlink {
    display: none;
  }

  .chatdatatoggle {
    background-color: #acd2b8;
    margin-left: 10px;
    color: #1c1d44;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }
  }
}
