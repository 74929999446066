.page {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  form {
    width: 100%;
    @media (min-width: 576px) {
      width: 300px;
    }
  }

  .label {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .loader {
    width: 100%;
    margin-top: 10px;
    // border: 1px solid red;
    min-height: 30px;
    display: flex;
    justify-content: center;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  // width: 270px;
  margin-bottom: 20px;
  position: relative;
  // align-items: center;
}

.input {
  font-size: 14px;
  padding: 5px;
  padding-left: 23px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  outline: none;
  margin-bottom: 10px;
}

.letter {
  position: absolute;
  left: 10px;
  top: 43px;
  font-size: 14px;
}

.input:hover,
.input:focus {
  border-color: #1890ff;
}

.button {
  margin-top: 0px;
  cursor: pointer;
  text-align: center;
  background-color: #1c1d44;
  color: #fff;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 300px;
  font-size: 16px;
  @media (max-width: 576px) {
    width: 100%;
  }
}

.button:hover,
.button:focus {
  background-color: #5b5b8f;
}

.warning {
  color: #e96767;
  position: absolute;
  top: 80px;
}

.back {
  // position: relative;
  display: block;
  margin-top: 30px;
  text-align: center;
}

.back:hover,
.back:focus {
  color: rgba(66, 170, 255, 0.656);
}

/* .back::before {
  position: absolute;
  content: "🠔";
  left: 110px;
} */