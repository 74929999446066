.footer {
  width: 100%;
  border-radius: 10px;
  grid-area: banner;
  margin-bottom: 20px;
  padding-bottom: 5px;

  &:hover {
    opacity: .8;
  }
}

@media (min-width: 936px) {
  .footer {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }
}
