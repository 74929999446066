.banner {
  display: block;
}

.bannerimg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

// @media (min-width: 680px) {
//   .banner {
//     width: 400px;
//     height: 100px;
//   }
// }

// @media (min-width: 940px) {
//   .banner {
//     width: 925px;
//     height: 100px;
//   }
// }