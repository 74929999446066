.page {
  margin-left: 20px;
  margin-top: 40px;
}

.heading {
  margin-bottom: 10px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.newpassword,
.repeatpassword {
  position: relative;
}

.pwdnote {
  color: grey;
  font-size: 12px;
  margin-bottom: 15px;
}

.label {
  font-size: 14px;
  margin-bottom: 15px;
}

.input {
  font-size: 14px;
  padding: 5px;
  padding-left: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  outline: none;
}

.eye {
  cursor: pointer;
  position: absolute;
  font-size: 16px;
  right: 12px;
  top: 42px;
}

.input:hover,
.input:focus {
  border-color: #1890ff;
}

.button {
  margin-top: 30px;
  cursor: pointer;
  text-align: center;
  background-color: #1c1d44;
  color: #fff;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  font-size: 16px;
}

.button:hover,
.button:focus {
  background-color: #5b5b8f;
}

.link {
  margin-top: 20px;
  display: block;
}

.link:hover,
.link:focus {
  color: rgba(66, 170, 255, 0.656);
}