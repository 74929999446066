.chartblock {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
}

.statslink {
  margin-left: 10px;
  margin-top: 25px;
  font-size: 16px;
}

.chatdatatoggle {
  background-color: #acd2b8;
  margin-left: 10px;
  color: #1c1d44;
  padding: 5px 10px;
  border-radius: 5px;
}

@media (min-width: 992px) {
  .chartblock {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
