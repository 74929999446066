/* Main colors */
$Yellow: #de9636;
$Red: #e6564c;
$LightGreen: #acd2b8;
$Blue: #2b326d;
/* Secondary colors */
$Raspberry: #da3166;
$DarkGreen: #5a624e;
$SecondaryBlue: #6e84be;
$DarkBlue: #1c1d44;
$White: #ffffff;

$mainColor: $Blue;
$lightColor: #e0fbfc;
// $lightColor: rgba($SecondaryBlue, 0.1);
// $secondaryColor: #98c1d9;
$secondaryColor: rgba($SecondaryBlue, 0.5);
$darkColor: $DarkBlue;
$oppositeColor: $Red;
