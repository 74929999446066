@import "../../../constants.scss";

.link {
  color: #6c757d;
  font-size: 1rem;
  padding-left: 10px;
  padding-bottom: 8px;
  &:hover,
  &:focus {
    color: $Red;
    // text-decoration: underline;
  }
}

@media (min-width: 768px) {
  .link {
    padding-bottom: 0;
    font-size: .875rem;
  }
}

@media (min-width: 872px) {
  .link {
    padding-bottom: 8;
    font-size: 1rem;
  }
}
