@import "../../constants.scss";

.section {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.heading,
.fullname,
.verstid,
.homeevent {
  text-align: center;
}

.heading {
  font-size: 46px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
}

.link {
  display: block;
  padding: 10px 20px;
  color: $White;
  text-align: center;
  font-size: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: $mainColor;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 500;
  width: 200px;
  &:hover,
  &:focus {
    background-color: $SecondaryBlue;
    color: $White;
  }
}

.username {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
}

.verstid {
  margin-bottom: 0;
}

.verstid,
.homeevent {
  font-size: 18px;
}

.blockimage {
  margin-bottom: 0;
}

.codeimage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.button {
  cursor: pointer;
  display: block;
    padding: 10px 20px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    border-radius: 3px;
    font-weight: 500;
    width: 200px;
    background-color: #2b326d;
}

.button:hover {
  background-color: #6e84be;
}

.buttonshop {
  cursor: pointer;
  display: block;
  padding: 10px 20px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  border-radius: 3px;
  font-weight: 500;
  width: 200px;
  background-color: #f14c15;
}

.buttonshop:hover,
.buttonshop:focus {
  background-color: #f1825c;
  color:#fff;
}

.icedata,
.icetitle {
  text-align: center;
  font-size: 16px;
  margin-bottom: 5px;
}

@media print {
  header,
  .heading,
  .button {
    visibility: hidden;
  }
}
