@import "../../constants.scss";

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  display: flex;
  justify-content: center;
}

body {
  font-family: "Roboto Regular", "Arial", sans-serif;
  padding: 0;
  margin: 0 auto;
  background-color: #fff;
  max-width: 1200px;
  display: flex;
  justify-content: center;
}

#root {
  width: 100%;
}

body, html {
  height: 100%;
  width: 100%;
}

.wrap {
  display: flex;
  flex-direction: column;
}

.header-block {
  order: 1;
}

.banner-block {
  order: 2;
}

.main-block {
  order: 3;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

// buttons

.table-buttons {
  display: flex;
  margin-bottom: 50px;
  margin-top: 20px;
  gap: 10px;
}
.ant-btn.ant-btn-primary.submit-button {
  background-color: $mainColor;
  border: 1px solid $mainColor;
  &:hover {
    color: $mainColor;
    background-color: transparent;
  }
}
.ant-btn.ant-btn-primary {
  background-color: $oppositeColor;
  border: 1px solid $oppositeColor;
  font-weight: 700;
  &:hover {
    background-color: lighten($oppositeColor, 10%);
  }
}
.ant-btn.ant-btn-default {
  &:hover {
    border: 1px solid $oppositeColor;
    color: $oppositeColor;
  }
}

@media (min-width: 936px) {
  .wrap {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .main-block {
    flex: 1 0 auto;
    order: 2;
    margin-bottom: 20px;
  }

  .banner-block {
    flex: 0 0 auto;
    order: 3;
  }
}
