@import "../../constants.scss";

.content {
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  min-height: calc(100vh - 180px);
  .link {
    display: block;
    padding: 10px 20px;
    color: $White;
    text-align: center;
    font-size: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: $mainColor;
    border-radius: 3px;
    cursor: pointer;
    font-weight: 500;
    width: 200px;
    &:hover,
    &:focus {
      background-color: $SecondaryBlue;
    }
  }
  .linkshop {
    background-color: $Yellow;

    &:hover,
    &:focus {
      background-color: rgba(222, 150, 54, .7);
    }
  }
  .linkclubs {
    background-color: $LightGreen;
    color: $DarkBlue;

    &:hover,
    &:focus {
      background-color: rgba(172, 210, 184, .7);
      color:rgba(28, 29, 68, .7)
    }
  }

  .list {
    margin-bottom: 0;
  }

  .item {
    margin-bottom: 30px;
  }

  .item:last-child {
    margin-bottom: 0;
  }

  .text {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 10px;
  }
  .heading {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }

  .blockicons {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .content {
    .heading {
      font-size: 16px;
      text-align: left;
      margin-left: 20px;
    }
    .link {
      margin-right: 40px;
      margin-left: 20px;
      font-size: 16px;
    }
    .item {
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
    }
    .text {
      display: block;
      font-size: 16px;
    }

    .blockicons {
      margin-left: 20px;
      text-align: left;
    }
  }
}

@media (min-width: 936px) {
  .content {
    margin-bottom: 20px;
  }
}